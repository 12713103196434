




















































import { Component, Vue, Prop } from 'vue-property-decorator';
import Currency from '@/modules/common/components/currency.vue';
import ParityBrokerDataModel from '../../models/parity-broker-data.model';

@Component({
    components: {
        Currency,
    },
    filters: {
        PriceFilter: (value: any) => {
            if (!value) return 'N/A';
            return Number(value).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
        },
    },
})
export default class BrokerInfo extends Vue {
    @Prop({
        type: Object,
        required: true,
    })
    data!: ParityBrokerDataModel;

    @Prop({
        type: Boolean,
        default: false,
    })
    isCurrentBrand!: boolean;

    private isExpanded: boolean = false;

    handlePriceToggle() {
        this.isExpanded = !this.isExpanded;
    }
}
