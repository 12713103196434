import { render, staticRenderFns } from "./popup-dif-tab.vue?vue&type=template&id=19c2b438&scoped=true&"
import script from "./popup-dif-tab.vue?vue&type=script&lang=ts&"
export * from "./popup-dif-tab.vue?vue&type=script&lang=ts&"
import style0 from "./popup-dif-tab.vue?vue&type=style&index=0&id=19c2b438&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c2b438",
  null
  
)

export default component.exports