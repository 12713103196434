

































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Screenshot extends Vue {
    @Prop({
        type: String,
        required: true,
    })
    title!: string;

    @Prop({
        type: String,
        default: null,
    })
    src!: string | null;

    @Prop({
        type: String,
        default: null,
    })
    rectangleUniqueness!: string | null;

    @Prop({
        type: Array,
        default: null,
    })
    rectCoord!: number[] | null;

    @Prop({
        type: Boolean,
        default: false,
    })
    isCurrentBrand!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    isMaxed: boolean = false;

    @Prop({
        type: Boolean,
        default: false,
    })
    isMin: boolean = false;

    @Prop({
        type: Function,
        required: true,
    })
    onToggle!: (title: string | null) => void;

    updated() {
        if (this.isCurrentBrand) {
            return;
        }

        const image = this.$refs[`${this.title}-img`] as HTMLImageElement;

        this.scrollToRect();
        this.setRectCoords();
    }

    xScale() {
        const image = this.$refs[`${this.title}-img`] as HTMLImageElement;

        if (!image) {
            return null;
        }

        if (!image.naturalWidth || !image.width) {
            return null;
        }

        return image.width / image.naturalWidth;
    }

    yScale() {
        const image = this.$refs[`${this.title}-img`] as HTMLImageElement;

        if (!image) {
            return null;
        }

        if (!image.naturalHeight || !image.height) {
            return null;
        }

        return image.height / image.naturalHeight;
    }

    rectX() {
        const xScale = this.xScale();

        if (!this.rectCoord || !this.rectCoord.length || !xScale) {
            return null;
        }

        return xScale * this.rectCoord[0];
    }

    rectWidth() {
        const xScale = this.xScale();

        if (!this.rectCoord || !this.rectCoord.length || !xScale) {
            return null;
        }

        return Math.abs(xScale * this.rectCoord[0] - xScale * this.rectCoord[2]);
    }

    rectY() {
        const yScale = this.yScale();

        if (!this.rectCoord || !this.rectCoord.length || !yScale) {
            return null;
        }

        return yScale * this.rectCoord[1];
    }

    rectHeight() {
        const yScale = this.yScale();

        if (!this.rectCoord || !this.rectCoord.length || !yScale) {
            return null;
        }

        return Math.abs(yScale * this.rectCoord[1] - yScale * this.rectCoord[3]);
    }

    handleLoad() {
        if (this.isCurrentBrand) {
            return;
        }

        this.scrollToRect();
        this.setRectCoords();
    }

    scrollToRect() {
        const wrapper = this.$refs[this.title] as HTMLElement;
        const rectY = this.rectY();

        if (!wrapper || !rectY) {
            return;
        }

        wrapper.scrollTop = rectY;
    }

    setRectCoords() {
        const rect = this.$refs[`${this.title}-rect`] as HTMLElement;

        if (!rect || !this.rectX || !this.rectWidth || !this.rectY || !this.rectHeight) {
            return;
        }

        rect.style.left = `${this.rectX()}px`;
        rect.style.top = `${this.rectY()}px`;
        rect.style.width = `${this.rectWidth()}px`;
        rect.style.height = `${this.rectHeight()}px`;
    }

    get expandName() {
        if (this.isCurrentBrand && !this.isMin) {
            return 'Expand';
        }
        return '';
    }

    get newTab() {
        if (this.isCurrentBrand && !this.isMin) {
            return 'Open new tab';
        }
        return '';
    }

    handleMinMaxClick() {
        this.onToggle(this.isMaxed ? null : this.title);
    }

    handleNewTab() {
        if (!this.src) {
            return;
        }
        window.open(this.src, '_blank');
    }

    isImageURL(url: string) {
        if (!url) {
            return false;
        }

        return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    }

    getOffset(el: Element) {
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY,
        };
    }

    onLoadIFrame() {
        const iframe = this.$refs[`${this.title}-iframe`] as HTMLIFrameElement;
        if (iframe && this.rectangleUniqueness) {
            const doc = iframe.contentWindow;
            if (doc) {
                doc.postMessage(this.rectangleUniqueness, '*');
            }
        }
    }
}
